<script>
import { fandomBasicMixin, fandomExtendedMixin } from 'src/modules/fandom_mixins_module.js';

export default {
  mixins: [ fandomBasicMixin, fandomExtendedMixin ],
  data: function() {
    return { };
  },
  mounted() {
  },
  computed: {
    rootStyle() {
      return {
        "background-image": this.getContentResponsiveImageURL("background_image")
      };
    },
  }
};
</script>

<template>
  <div v-easyadmin="easyadminId" :id="anchorId" v-if="isContentVisibleToUser()" :class="contentType" class="py-5 themed-item" :style="[theme, rootStyle]" stripe-accordion-component>
    <div class="container">
      <text-section :content="content" :opt-container-content="containerContent" class="mb-4"></text-section>
      <div class="separator w-100"></div>
      <div v-for="(item, index) in content.accordion" :key="index">
        <a :href="`#item-${index}-${content.$id}`" class="deco-none w-100 collapsed accordion" data-toggle="collapse">
          <div class="d-flex my-4 align-items-center">
            <div class="col-auto">
              <div class="icon-container d-flex align-items-center justify-content-center rounded-circle mr-3 text-primary">
              </div>
            </div>
            <div class="col font-weight-bold">{{item.title}}</div>
          </div>
        </a>
        <div class="collapse" :id="`item-${index}-${content.$id}`" :data-parent="`#${anchorId}`">
          <p class="mb-4" v-html="item.description"></p>
        </div>
        <div class="separator w-100"></div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
[stripe-accordion-component] {
  background-position: top;
  background-size: 100% auto;

  .icon-container {
    background-color: var(--theme-border-color);
    width: 2rem;
    height: 2rem;
  }

  a.accordion.collapsed .icon-container::before {
    font-family: "Font Awesome 6 Pro";
    content: "\2b";
  }

  a.accordion .icon-container::before {
    font-family: "Font Awesome 6 Pro";
    content: "\f00d";
  }

  .separator {
    height: 1px;
    background-color: var(--gray);
  }
  
}
</style>
