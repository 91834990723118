<script>
import { fandomBasicMixin, fandomExtendedMixin, fandomTileMixin } from "src/modules/fandom_mixins_module.js";
import { get } from 'lodash';

export default {
  mixins: [fandomBasicMixin, fandomExtendedMixin, fandomTileMixin],
  data() {
    return { };
  },
  computed: {
    url() {
      return get(this.content, ['url','url'], Fandom.getContentURL(this.content));
    }
  }
}
</script>

<template>
  <div
    v-easyadmin="easyadminId" 
    :class="contentType"
  >
    <a
      class="deco-none cursor-pointer bg-transition"
      :target="getTarget(content.url)"
      :href="url"
      :style="theme"
      tile-component
    >
      <div class="ar-16by9 position-relative overflow-hidden">
        <div class="position-absolute-center bg-cover-center bg" :style="{'background-image': getContentResponsiveImageURL('thumbnail')}"></div>
      </div>
      <div class="py-4">
        <div class="tile__text-section mb-2">
          <component
            v-if="content.title"
            :class="content.title_class || 'h5'"
            :is="content.title_tag || 'h4'"
            :style="{'color': content.text_color}"
          >
            {{content.title}}
          </component>
          <component
            v-if="content.subtitle"
            :is="content.subtitle_tag || 'p'"
            :class="content.subtitle_class"
            :style="{'color': `${content.text_color}`}"
            class="line-clamp-2"
          >
            {{content.subtitle}}
          </component>
        </div>
        <u class="font-weight-bold">{{ft('globals.tile.discover_more')}}</u>
      </div>
    </a>
  </div>
</template>

<style lang="scss" scoped>
[tile-component] {
  transition: all .25s;

  h3, ::v-deep p {
    margin-bottom: 0 !important;
  }

  .line-clamp-2{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
  }
}
</style>