<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { get } from 'lodash';

export default {
  mixins: [fandomBasicMixin],
  data() {
    return { };
  },
  props: {
    eventbrite: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getTextField(field, optField) {
      if (optField && this.content[optField]) {
        return this.content[optField];
      }
      return get(this.content, [field, "text"], this.content[field]);
    },
    getUrlField(field) {
      return get(this.content, [field,"url"], this.content[field]);
    }
  },
  computed: {
    date() {
      // If the date is coming from extra fields then it is a string
      let date = this.content.start;
      if (date instanceof Object) {
        // If the date is coming from eventbrite event data then it is an object with value in "utc"
        date = date.utc;
      }
      return new Date(date).toLocaleDateString([], {
        day: "numeric",
        month: "long",
        year: "numeric"
      });
    },
    title() {
      return this.getTextField("name", "title");
    },
    description() {
      return this.getTextField("summary");
    },
    imageUrl() {
      return this.getUrlField("logo");
    },
    url() {
      return this.getUrlField("url");
    },
    easyadminLink() {
      return this.eventbrite ? `https://www.eventbrite.com/myevent?eid=${this.content.id}` : this.easyadminId;
    }
  }
};
</script>

<template>
  <div v-easyadmin="easyadminLink" v-if="isContentVisibleToUser()" :class="contentType" class="d-flex flex-column tile p-3" tile-eventbrite-component>
    <div class="ar-2by1 bg-cover-center w-100" :style="{backgroundImage: 'url(' + imageUrl + ')'}"></div>
    <div class="d-flex align-items-center justify-content-between py-2">
      <div>{{date}}</div>
      <div class="sold-out small px-2 text-uppercase pt-1" v-if="eventbrite && (content.ticket_availability || {}).is_sold_out">{{ft('globals.sold_out')}}</div>
    </div>
    <div class="flex-grow-1 d-flex flex-column justify-content-between">
      <div>
        <div class="h5 title">{{title}}</div>
        <div class="description">{{description}}</div>
      </div>
      <div class="d-flex justify-content-between align-items-center mt-3">
        <button
          :class="{'disabled': (content.ticket_availability || {}).is_sold_out}"
          :disabled="(content.ticket_availability || {}).is_sold_out"
          v-if="eventbrite"
          class="btn btn-outline-dark px-4 py-3"
          :id="`eventbrite-widget-modal-trigger-${content.id}`"
          type="button"
        >{{ft('globals.book')}}</button>
        <a :class="{'btn btn-outline-dark': !eventbrite}" :href="url" target="_blank">{{ft('globals.details')}}</a>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
[tile-eventbrite-component] {
  background-color: #eee;

  .sold-out {
    background-color: $danger;
    color: #fff;
  }

  .title, .description {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .title {
    white-space: nowrap;
  }

  .description {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }

  @include media-breakpoint-up(sm) {
    .description {
      -webkit-line-clamp: 2;
    }
  }
}
</style>
